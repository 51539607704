import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';

@Injectable({
    providedIn: 'root'
})
export class SecurityService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadQRCode(): Observable<any> {
        return this.httpService
            .get<any>(this.createUrl('user/two-factor-qr-code'))
            .pipe(map((response: any) => response.svg));
    }

    loadRecoveryCodes(): Observable<any> {
        return this.httpService.get<any>(this.createUrl('user/two-factor-recovery-codes'));
    }

    enableTwoFactorAuthentication(): Observable<any> {
        return this.httpService.post<any>(this.createUrl('user/two-factor-authentication'), {});
    }

    deleteTwoFactorAuthentication(): Observable<any> {
        return this.httpService.delete<any>(this.createUrl('user/two-factor-authentication'));
    }
}
